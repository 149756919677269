import React from 'react';
import { withPrefix, graphql } from "gatsby";

// Layout Imports
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';

// Components
import Content, { HTMLContent } from "../../components/content-renderer";
import PageHeader from '../../components/page-header';
import Section from '../../components/section';

const TermsAndConditions = ({ data }) => {
  const { markdownRemark: pageInfo } = data;
  const PageContent = HTMLContent || Content;
  const headerImage = pageInfo.frontmatter.image.relativePath;

  return (
    <Default>
      <Seo
        title={pageInfo.frontmatter.title}
        description={pageInfo.frontmatter.description}
      />
    
      <PageHeader
        heading={pageInfo.frontmatter.title}
        imageSource={`${withPrefix("/")}img/${headerImage}`}
        description={pageInfo.frontmatter.description}
      />
      
      <Section
				label='Main text content'
				variants={['padded']}
			>
        <div className='content-renderer'>
          <PageContent content={pageInfo.html} />
        </div>
      </Section>
    </Default>
  );
};

export default TermsAndConditions;

export const TermsAndConditionsQuery = graphql`
  query TermsAndConditions($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        image {
            relativePath
        }
        description
      }
    }
  }
`;